import Header from './MainHeader';
import Footer from './MainFooter';

export default function layout({ children }) {
    return (
        <>
            <Header />
            <main>{children}</main>
            <Footer />
        </>
    )
}