import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../styles/styles.scss';
import SourceFlowHead from "@sourceflow-uk/sourceflow-head";
import metaObject from "../.sourceflow/metadata.json";

import Layout from '../components/layout'

function MyApp({ Component, pageProps }) {
	return (
		<Layout>
			<SourceFlowHead metaObject={metaObject} addTracker={true} />
			<Component {...pageProps} />
		</Layout>
	)
}

export default MyApp
