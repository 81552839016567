import SourceFlowImage from "@sourceflow-uk/sourceflowimage"
import {
    Container,
    Row,
    Col,
    Card,
    CardGroup,
    Nav,
    NavItem,
    CardBody
} from 'reactstrap';
import styles from './styles.module.scss';
import Link from "next/link"
import Logo from "/public/site-assets/svg/logo-black.svg"
import ImpellamGroup from "/public/site-assets/svg/impellam-group.svg"
import React, {
    useRef,
    useEffect,
    useState
} from "react";
import { useRouter } from 'next/router';
import Sourceflow from '/public/sourceflow_logo.svg';
import SocialLinks from '/components/SocialLinks';
import FooterCopyrightNavigations from '../../.sourceflow/footer_copyright_navigations.json';
import FooterAboutLorienNavigations from '../../.sourceflow/footer_about_srg_navigations.json';
import FooterJobSeekersNavigations from '../../.sourceflow/footer_job_seekers_navigations.json';
import FooterDocumentsNavigations from '../../.sourceflow/footer_documents_navigations.json';
import FooterSolutionsNavigations from '../../.sourceflow/solutions.json';
import FooterSolutionsNavigationsUS from "../../.sourceflow/solutions_us.json";

export default function MainFooter({
    global,
    pathPrefix,
    customClass = "",
}) {
    const router = useRouter();

    return (
        <section className={`${styles.root} ${customClass} position-relative`}>
            <Container>
                <hr></hr>
                <div className="pt-3">
                    <Link href="/">
                        <a aria-label="Logo" title="SRG">
                            <Logo />
                        </a>
                    </Link>
                    <Row className="mt-4">
                        <Col md={3} className="d-flex flex-wrap justify-content-between">
                            <div className="mb-4 navigations">
                                <p className="mb-1">Get in Touch</p>
                                {/* <p className="mb-0">
                                    <Link href={router.pathname.startsWith("/us") ? "" : ""}>
                                        <a>{router.pathname.startsWith("/us") ? "" : ""}</a>
                                    </Link>
                                </p> */}
                                {router.pathname.startsWith("/us") ?  <p>
                                    <Link href="mailto:connect@srgtalent.com">
                                        <a>
                                            {router.pathname.startsWith("/us") ? "connect@srgtalent.com" : ""}
                                        </a>
                                    </Link>
                                </p> : <p>
                                    <Link href="mailto:info@srgtalent.com">
                                        <a>
                                            {router.pathname.startsWith("/") ? "info@srgtalent.com" : ""}
                                        </a>
                                    </Link>
                                </p>}
                            </div>
                            <SocialLinks
                                customClass="social-links w-100 mb-5 mb-md-0 d-flex align-items-end"
                            />
                        </Col>
                        <Col md={9}>
                            <Row className="justify-content-between">
                                <Col md={6} lg={2}>
                                    <p className="mb-1">For Job Seekers</p>
                                    <ul className="list-unstyled navigations" >
                                        {FooterJobSeekersNavigations.category_values.map((post, index) => (
                                            <li key={index}>
                                                <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}${post.link.en}`}>
                                                    <a className="text-decoration-none">
                                                        {post.title.en}
                                                    </a>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </Col>
                                <Col md={6} lg={2}>
                                    <p className="mb-1">Business Solutions</p>
                                    <ul className="list-unstyled navigations" >
                                        {router.pathname.startsWith("/us") ? 
                                            (FooterSolutionsNavigationsUS.category_values.filter((hide) => !hide.hidden_in_menu).map((post, index) => (
                                                <li key={index}>
                                                    <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/business-solutions/${post.url_slug}/`}>
                                                        <a className="text-decoration-none">
                                                            {post.title.en}
                                                        </a>
                                                    </Link>
                                                </li>
                                            )))
                                            :
                                            (FooterSolutionsNavigations.category_values.filter((hide) => !hide.hidden_in_menu).map((post, index) => (
                                                <li key={index}>
                                                    <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/business-solutions/${post.url_slug}/`}>
                                                        <a className="text-decoration-none">
                                                            {post.title.en}
                                                        </a>
                                                    </Link>
                                                </li>
                                            )))
                                        }
                                    </ul>
                                </Col>
                                <Col md={6} lg={2}>
                                    <p className="mb-1">About SRG</p>
                                    <ul className="list-unstyled navigations" >
                                        {FooterAboutLorienNavigations.category_values.
                                        filter(post => post.country?.en.includes("All") || post.country?.en.includes(`${router.pathname.startsWith("/us") ? "United States" : "United Kingdom"}`)).
                                        map((post, index) => (
                                            <li key={index}>
                                                <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}${post.link.en}`}>
                                                
                                                    <a className="text-decoration-none">
                                                        {post.title.en}
                                                    </a>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </Col>
                                <Col md={6} lg={2}>
                                    <p className="mb-1">Insights</p>
                                    <ul className="list-unstyled navigations" >
                                        {/* {FooterAboutLorienNavigations.category_values.map((post, index) => (
                                            <li key={index}>
                                                <Link href={`${post.link.en}`}>
                                                    <a className="text-decoration-none">
                                                        {post.title.en}
                                                    </a>
                                                </Link>
                                            </li>
                                        ))} */}
                                        <li>
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/blog/`}>
                                                <a className="text-decoration-none">
                                                    News & Insights
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/blog/tag/guides-and-reports/`}>
                                                <a className="text-decoration-none">
                                                    Guides & Reports
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/blog/tag/podcasts-and-webinars/`}>
                                                <a className="text-decoration-none">
                                                    Podcasts & Webinars
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/blog/tag/career-advice/`}>
                                                <a className="text-decoration-none">
                                                    Career Advice
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/blog/tag/case-study/`}>
                                                <a className="text-decoration-none">
                                                    Case Studies
                                                </a>
                                            </Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col md={6} lg={2}>
                                    <p className="mb-1">Documents</p>
                                    <ul className="list-unstyled navigations" >
                                        {FooterDocumentsNavigations.category_values.map((post, index) => (
                                            <li key={index}>
                                                {post?.new_tab ?
                                                    <Link href={`${post.link.en}`}>
                                                        <a className="text-decoration-none" target="_blank" rel="noopener noreferrer">
                                                            {post.title.en}
                                                        </a>
                                                    </Link> :
                                                    <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}${post.link.en}`}>
                                                        <a className="text-decoration-none">
                                                            {post.title.en}
                                                        </a>
                                                    </Link>}
                                            </li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-start mt-4">
                    <span className="impellem-group d-flex align-items-end me-4">
                        <small>Part Of</small>
                        <Link href="https://www.impellam.com/">
                            <a target="_blank" rel="noopener noreferrer" title="ImpellAm Group" className="ms-1">
                                <ImpellamGroup /></a></Link>
                    </span>
                    {router.pathname.startsWith("/us") ? 
                    <div className="my-4 my-md-0">
                        <small>© SRG 300 Trade Center Suite 3610 Woburn, Massachusetts 01801</small>
                    </div> : 
                    <div className="my-4 my-md-0">
                    <small>© SRG First Floor, Mulberry House, Parkland Square, 750 Capability Green, Luton, LU1 3LU</small>
                    </div>}
                    {/* <div className="my-4 my-md-0">
                        <small>© SRG First Floor, Mulberry House, Parkland Square, 750 Capability Green, Luton, LU1 3LU</small>
                    </div> */}
                    <div className="text-nowrap d-flex align-items-end">
                        <p className="mb-0 me-3"><small>© {new Date().getFullYear()} SRG.</small></p>
                        <p className="mb-0 me-1"><small>Site by</small></p>
                        <Link href="https://www.sourceflow.co.uk/">
                            <a className="sourceflow" rel="noopener noreferrer" target="_blank" title="SourceFlow">
                                <Sourceflow />
                            </a>
                        </Link>
                    </div>
                </div>
                <ul className="d-flex flex-wrap justify-content-md-center list-unstyled footer-navigations-2 mx-auto my-4 pt-3" style={{ maxWidth: '800px', borderTop: '1px solid #dfdfdf' }}>
                    {FooterCopyrightNavigations.category_values.map((post, index) => (
                        <li className="me-md-3 mb-1 pe-2 pe-md-0" key={index}>
                            {post.new_tab ?
                                <Link href={`${post.link.en}`}>
                                    <a className="text-decoration-none" target="_blank" rel="noopener noreferrer">
                                        <small>{post.title.en}</small>
                                    </a>
                                </Link> :
                                <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}${post.link.en}`}>
                                    <a className="text-decoration-none">
                                        <small>{post.title.en}</small>
                                    </a>
                                </Link>}

                        </li>
                    ))}
                </ul>
            </Container>
        </section>
    )
}